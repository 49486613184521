<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row>
        <v-col cols="12">
          <base-heading title="404" />

          <base-subheading class="primary--text" title="Oh!" />

          <base-subheading
            space="8"
            class="text-h6"
            :title="$t('notFoundText')"
          />

          <v-row justify="center">
            <v-icon size="170" class="mx-auto mb-12" color="primary">
              mdi-information-outline
            </v-icon>
          </v-row>

          <div class="text-center">
            <base-btn :to="{ name: 'Welcome' }">{{ $t("getMeOut") }}</base-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "FourOhFourSection",

  provide: {
    heading: { align: "center" },
  },
};
</script>
